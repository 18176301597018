import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { BaseUrl, token } from "../utils/Token";
import adminLayout from "../hoc/adminLayout";
import { removeBackslashes } from "../components/RemoveBacklashes";

function OrderList() {
  const [user, setUser] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };




  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${BaseUrl}/order_list`, {
  //       headers,
  //     });
  //     // console.log(response)
  //     // setUser(response.data.data);
  //     if (response.data.data) {
  //       setUser(response.data.data)
  //     } else {
  //       setUser([])
  //     }
  //   } catch (error) {
  //     console.error("Error fetching category data:", error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/invoice_list`, {
        headers,
      });
      if (response.data.data) {
        setUser(response.data.data)
      } else {
        setUser([])
      }


    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {

        let saledata = removeBackslashes(params.row.sale);
        saledata = JSON.parse(saledata)
        let image = saledata[0].product_variant.product.image


        return <div>
          <img src={`${BaseUrl}/${image}`} alt="" style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }} />
        </div>;
      }
    },

    {
      field: "short_name",
      headerName: "Short Name",
      flex: 1,
      renderCell: (params) => {

        let saledata = removeBackslashes(params.row.sale);
        saledata = JSON.parse(saledata)
        let name = saledata[0].product_variant.product.short_name

        return <div>
          {name}
        </div>;
      }
    },

    {
      field: "payable_order_value",
      headerName: "Payable Amount",
      flex: 1,


    },

    {
      field: "Items",
      headerName: "Items",
      flex: 1,
      renderCell: (params) => {

        let saledata = removeBackslashes(params.row.sale);
        saledata = JSON.parse(saledata)
        let name = saledata.length

        return <div>
          {name}
        </div>;
      }

    },

    {
      field: "price_range",
      headerName: "Price Range",
      flex: 1,
      renderCell: (params) => {

        let saledata = removeBackslashes(params.row.sale);
        saledata = JSON.parse(saledata)
        let name = saledata[0].product_variant.product.price_range


        return <div>
          {name}
        </div>;
      }
    },





  ];

  const navigate = useNavigate()

  const reversedRows = [...user]?.reverse();

  const handleRowclick = (params) => {
    navigate('/productdetail', { state: params.row })
    console.log(params.row)

  }

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Invoice List</h4>
                <div className="">
                  <DataGrid
                    rows={reversedRows}
                    columns={columns}
                    getRowId={getRowId}
                    onRowClick={handleRowclick}
                    initialState={{
                      ...user.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}

                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}


export default adminLayout(OrderList);
