import React, { useState } from 'react'
import adminLayout from '../../hoc/adminLayout'
import { useLocation, useParams } from 'react-router-dom'
import { removeBackslashes } from '../../components/RemoveBacklashes';
import moment from 'moment';
import { BaseUrl } from '../../utils/Token';
import notfound from "../../assets/images/notfound.jpg"

function Detail() {

    const { state } = useLocation();
    // console.log(state)

    const [data, setdata] = useState(state)


    let saledata = removeBackslashes(state?.sale);
    saledata = JSON.parse(saledata)
    let lenghtdata = saledata.length


    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Summary</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Items</button>
                                </li>

                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="w-100 h-100">
                                                <div class="w-100 h-100 bg-light box-shadow-1 card">
                                                    <div class="">
                                                        <table class="table-sm mb-0  t-12 text-secondary table table-borderless">
                                                            <tbody>
                                                                <tr>
                                                                    <td>No of Items</td>
                                                                    <td class="text-end"> {lenghtdata}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tax</td><td class="text-end">₹ {data.order.gst}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Dicount</td><td class="text-end"> ₹ {data.order.discount_on_mrp}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dicount on Promom</td><td class="text-end"> ₹ {data.order.discount_on_promo}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td>Delivery Charge</td>
                                                                    <td class="text-end"> ₹ {data.order.shipping_charge}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Packaging Charge</td>
                                                                    <td class="text-end"> ₹ 0</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {console.log(saledata)}
                                        <div class="col-md-6">
                                            <div class="w-100 h-100">
                                                <div class="w-100 h-100 bg-light box-shadow-1 card">
                                                    <div class="">
                                                        <table class="table-sm mb-0  t-12 text-secondary table table-borderless">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Total Amount</td>
                                                                    <td class="text-end">₹ {(data.payable_order_value).toFixed(2)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Order Date</td>
                                                                    <td class="text-end">{moment(data.order.created_at).format("MM-DD-YYYY")}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="row gy-4 mb-4">
                                        {saledata.map((item) => {
                                            return (
                                                <>
                                                    <div className="col-md-4">
                                                        <div className="card h-100 shadow">
                                                            <img src={`${BaseUrl}/${item.product_variant.product?.image}`} onError={(e) => {
                                                                e.target.src = notfound;
                                                                e.target.onerror = null;
                                                            }} alt="" style={{ height: "200px", objectFit: "contain" }} />
                                                            <div className="p-3">
                                                                <div className="">
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <h6>{item?.product_variant?.product?.short_name}</h6>
                                                                        <h6 className=''>&#8377;{item.product_variant.selling_price_by_vendor}</h6>
                                                                    </div>
                                                                    <p className='text-secondary'>{item.product_variant.product.description}</p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default adminLayout(Detail)
