import React from "react";
import "../../assets/css/login.css"
import { Link } from 'react-router-dom';
import authLayout from "../../hoc/authLayout";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <>
      <div className="reset-password-section text-center">
        <h3><i className="fa fa-lock fa-4x"></i></h3>
        <h2 className="text-center">Forgot Password?</h2>
        <p>You can reset your password here.</p>
        <div className="panel-body">

          <form id="register-form" role="form" autocomplete="off" className="form" method="post">

            <div className="form-group">
              <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue"></i></span>
              <input id="email" name="email" placeholder="email address" className="form-control form-control-lg" type="email" />
            </div>

            <div className="form-group mt-2">
              <button type="button" className="btn btn-primary btn-lg">Reset Password</button>
              <p className="small fw-bold mt-2 pt-1 mb-0">Remember your password ? <Link to="/login"
                className="link-danger">Login</Link></p>
            </div>

          </form>

        </div>
      </div>
    </>
  }
}

export default authLayout(ResetPassword);

















// import React, { useState } from "react";
// import "../../assets/css/login.css";
// import { Link, useNavigate } from 'react-router-dom';
// import { Button, Form, FormGroup } from "react-bootstrap";
// import authLayout from "../../hoc/authLayout";
// import axios from "axios";

// const LoginPage = () => {
//     const [username, setUsername] = useState('');
//     const [otp, setOtp] = useState('')
//     const [message, setMessage] = useState('');
//     const [status, setStatus] = useState('');
//     const [isClicked, setIsClicked] = useState(false)
//     const navigate = useNavigate()



//     const handleClick = async (event) => {
//         event.preventDefault();

//         try {
//             const response = await axios.post('http://62.72.30.137:8000/enter_username', {
//                 username: username
//             });

//             setMessage(response.data.message);
//             setStatus(response.data.status);
//             console.log(response.data.message);
//             if (response.data.status === 'OK') {
//                 setIsClicked(true)
//             }
//         } catch (error) {
//             console.error(error);
//         }
//     };
//     const handleOtpClick = (e) => {
//         e.preventDefault();

//         // Make the API request to login
//         fetch(`62.72.30.137:8000/authenticated_login`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify({ username, otp })

//         })

//             .then(response => {

//                 if (response.ok) {
//                     // Handle successful login
//                     navigate("/"); // Redirect to the dashboard page
//                 } else {
//                     // Handle login error
//                     console.log(response)
//
//                 }
//             })
//             .catch(error => {
//                 console.error("Login error:", error);
//
//             });
//     };

//     const handleChange = (event) => {
//         setUsername(event.target.value);
//         setOtp(event.target.value)
//     };

//     return (
//         <>


//             <Form onSubmit={handleClick} className="login-form">
//                 <div className="d-flex align-items-center my-4">
//                     <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
//                 </div>
//                 <FormGroup className="mt-3">
//                     <label className="mb-1 fw-bold" htmlFor="name">
//                         name
//                     </label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         placeholder="name"
//                         required
//                         id="name"
//                         onChange={handleChange}
//                     />
//                 </FormGroup>
//                 <div className="mt-3">
//                     <Link to="/reset-password" className="text-body">
//                         Forgot password?
//                     </Link>
//                 </div>
//                 <Button className="btn mt-3 auth_btn" type="submit">
//                     Login
//                 </Button>
//             </Form>


//             {message && <p>Message: {message}</p>}
//             {status && <p>Status: {status}</p>}
//         </>
//     );
// };

// export default authLayout(LoginPage);

// when user click on Login user get otp then name will hide and show name and otp field and login button on this api 62.72.30.137:8000/authenticated_login
















// {
//   isClicked ? <Form onSubmit={handleOtpClick} className="login-form">

//       <FormGroup className="mt-3">
//           <label className="mb-1 fw-bold" htmlFor="otp">
//               Otp
//           </label>
//           <input
//               type="text"
//               className="form-control"
//               placeholder="Otp"
//               required
//               id="otp"
//               onChange={handleChange}
//           />
//       </FormGroup>
//       <FormGroup className="mt-3">
//           <label className="mb-1 fw-bold" htmlFor="name">
//               name
//           </label>
//           <input
//               type="text"
//               className="form-control"
//               placeholder="username"
//               required
//               id="username"
//               onChange={handleChange}
//           />
//       </FormGroup>

//       <Button className="btn mt-3 auth_btn" type="submit">
//           Login
//       </Button>
//   </Form> : <Form onSubmit={handleClick} className="login-form">
//       <div className="d-flex align-items-center my-4">
//           <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
//       </div>
//       <FormGroup className="mt-3">
//           <label className="mb-1 fw-bold" htmlFor="name">
//               name
//           </label>
//           <input
//               type="text"
//               className="form-control"
//               placeholder="username"
//               required
//               id="username"
//               onChange={handleChange}
//           />
//       </FormGroup>
//       <div className="mt-3">
//           <Link to="/reset-password" className="text-body">
//               Forgot password?
//           </Link>
//       </div>
//       <Button className="btn mt-3 auth_btn" type="submit">
//           Login
//       </Button>
//   </Form>
// }