import React, { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import axios from "axios";
import { BaseUrl, token } from "../../utils/Token";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function UserDetailComp(props) {
  const [username, setUsername] = useState("");

  const [image, setImage] = useState("");
  const [type, setType] = useState("");

  const [InfluencerData, setInfluencerData] = useState(false);
  const [data, setData] = useState([]);
  const [bankVerified, setBankVerified] = useState("");
  const [pan, setPan] = useState("");
  const [panverified, setPanVerified] = useState("");
  const [adharVerified, setAdharVerified] = useState("");
  const [Gst, setGst] = useState("");
  const [gstVerified, setgstVerified] = useState("");
  const [SocialLinks, setSocialLinks] = useState("");
  const [Adhar, setAdhar] = useState("");
  const [file, setFile] = useState(null);
  const [aadharfront, setAadharFront] = useState(null);
  const [aadharback, setAadharBack] = useState(null);
  const [panimg, setPanimg] = useState(null);
  const [rows, setRows] = useState([{ type: "", value: "" }]);
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [phone, setphone] = useState("");
  const [open, setOpen] = useState(false);

  const [enlargedSrc, setEnlargedSrc] = useState(null);

  const handleImageClick = (src) => {
    setEnlargedSrc(src);
  };

  const closeEnlargedView = () => {
    setEnlargedSrc(null);
  };

  const { id } = useParams();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        //   "http://62.72.30.137:8000/fetch_users_admin?type=User",
        `${BaseUrl}/fetch_users_admin?id=${id}`,
        {
          headers,
        }
      );
      setData(response.data.data);
      console.log(response.data.data);
      const dataUser = response.data.data;
      setUsername(dataUser.username);
      setImage(dataUser.image);
      setType(response.data.data.type);
      setBankVerified(dataUser.is_bank_verified);
      setAdhar(dataUser.aadhaar);
      setphone(dataUser.phone);
      setPan(dataUser.pan);
      setGst(dataUser.gst);
      setAdharVerified(dataUser.is_aadhaar_verified);
      setPanVerified(dataUser.is_pan_verified);
      setPan(dataUser.pan);
      setGst(dataUser.gst);
      setgstVerified(dataUser.is_gst_verified);
      setFile(dataUser.image);
      setAccountNumber(dataUser.bank_details.acn);
      setBankName(dataUser.bank_details.bn);
      setIfscCode(dataUser.bank_details.ifsc);
      setAccountHolderName(dataUser.bank_details.ahn);
      setFile(dataUser.image);
      setAadharFront(dataUser.aadhaar_front);
      setAadharBack(dataUser.aadhaar_back);
      setPanimg(dataUser.pan_image);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (type == "Influencer") {
      setInfluencerData(true);
    } else {
      setInfluencerData(false);
    }
  });

  const handleUserForm = async (e) => {
    e.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append("_id", id);
    formData.append("username", username);
    formData.append("type", type);
    formData.append("file", file);
    formData.append("is_bank_verified", bankVerified);
    formData.append("image_1", aadharfront);
    formData.append("image_2", aadharback);
    formData.append("aadhaar", Adhar);
    formData.append("is_aadhaar_verified", adharVerified);
    formData.append("is_pan_verified", panverified);
    formData.append("pan", pan);
    formData.append("image_3", panimg);
    formData.append("gst", Gst);
    formData.append("is_gst_verified", gstVerified);
    formData.append("phone", phone);
    formData.append("bank_details[acn]", accountNumber);
    formData.append("bank_details[bn]", bankName);
    formData.append("bank_details[ifsc]", ifscCode);
    formData.append("bank_details[ahn]", accountHolderName);

    rows.forEach((item, index) => {
      formData.append(`social_links[${index}][type]`, item.type);
      formData.append(`social_links[${index}][value]`, item.value);
    });

    axios
      .put(`${BaseUrl}/update_user_admin`, formData, {
        headers: headers,
      })
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        if (response.data.status == "OK") {
          toast.success("data Updated Successfully");
          setOpen(false);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.error("data not Updated");
        setOpen(false);
      });
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile); // Store the uploaded file directly
  };

  const handlefrontAdhar = (event) => {
    const uploadaadharFront = event.target.files[0];
    // Store the uploaded file directly
    setAadharFront(uploadaadharFront);
  };

  const handleBackAdhar = (event) => {
    const uploadaadharBack = event.target.files[0];
    setAadharBack(uploadaadharBack);
  };
  const handlePan = (event) => {
    const uploadPan = event.target.files[0];
    setPanimg(uploadPan);
  };

  const addRow = (e) => {
    e.preventDefault();
    setRows([...rows, { type: "", value: "" }]);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  addProduct">
                <h4 className="px-3 py-3">Details</h4>
                <div className="">
                  <Form onSubmit={handleUserForm}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={username}
                            name="name"
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="d-flex w-100 gap-3">
                          {/* <img
                            src={`${BaseUrl}/${file}`}
                            className="img-fluid"
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "30px",
                            }}
                          /> */}
                          <div>
                            {enlargedSrc && (
                              <div
                                className="enlarged-image-container"
                                style={{
                                  background:
                                    "RGBA(0,0,0,.5) url(" +
                                    enlargedSrc +
                                    ") no-repeat center",
                                  backgroundSize: "contain",
                                  width: "100%",
                                  height: "100%",
                                  position: "fixed",
                                  zIndex: "10000",
                                  top: "0",
                                  left: "0",
                                  cursor: "zoom-out",
                                }}
                                onClick={closeEnlargedView}
                              />
                            )}
                            <img
                              src={`${BaseUrl}/${file}`}
                              className="img-fluid img-enlargable"
                              alt=""
                              style={{
                                width: "120px",
                                height: "100px",
                                borderRadius: "30px",
                              }}
                              onClick={() =>
                                handleImageClick(`${BaseUrl}/${file}`)
                              }
                            />
                          </div>
                          <div className="w-100">
                            <label htmlFor="" className="fw-bold">
                              {" "}
                              Image
                            </label>
                            <input
                              type="file"
                              name="file"
                              className="form-control w-100"
                              onChange={handleFileUpload}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Type
                          </label>
                          <select
                            class="form-select"
                            name="type"
                            aria-label="Default select example"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option selected>Open this select menu</option>
                            <option value="User">User</option>
                            <option value="Influencer">Influencer</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={phone}
                            name="phone"
                            onChange={(e) => setphone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      {InfluencerData ? (
                        <>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Is Bank Verified
                              </label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="is_bank_verified"
                                value={bankVerified}
                                onChange={(e) =>
                                  setBankVerified(e.target.value)
                                }
                              >
                                <option selected>Open this select menu</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="d-flex w-100 gap-3">
                              {/* <img
                                src={`${BaseUrl}/${aadharfront}`}
                                className="img-fluid"
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "30px",
                                }}
                              /> */}

                              <div>
                                {enlargedSrc && (
                                  <div
                                    className="enlarged-image-container"
                                    style={{
                                      background:
                                        "RGBA(0,0,0,.5) url(" +
                                        enlargedSrc +
                                        ") no-repeat center",
                                      backgroundSize: "contain",
                                      width: "100%",
                                      height: "100%",
                                      position: "fixed",
                                      zIndex: "10000",
                                      top: "0",
                                      left: "0",
                                      cursor: "zoom-out",
                                    }}
                                    onClick={closeEnlargedView}
                                  />
                                )}
                                <img
                                  src={`${BaseUrl}/${aadharfront}`}
                                  className="img-fluid img-enlargable"
                                  alt=""
                                  style={{
                                    width: "120px",
                                    height: "100px",
                                    borderRadius: "30px",
                                  }}
                                  onClick={() =>
                                    handleImageClick(
                                      `${BaseUrl}/${aadharfront}`
                                    )
                                  }
                                />
                              </div>

                              <div className="w-100">
                                <label htmlFor="" className="fw-bold">
                                  {" "}
                                  Aadhaar Front
                                </label>
                                <input
                                  type="file"
                                  name="image_1"
                                  className="form-control"
                                  onChange={handlefrontAdhar}
                                />
                              </div>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <FormGroup className="d-flex w-100 gap-3">
                                {/* <img
                                  src={`${BaseUrl}/${aadharback}`}
                                  className="img-fluid"
                                  alt=""
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "30px",
                                  }}
                                /> */}

                                <div>
                                  {enlargedSrc && (
                                    <div
                                      className="enlarged-image-container"
                                      style={{
                                        background:
                                          "RGBA(0,0,0,.5) url(" +
                                          enlargedSrc +
                                          ") no-repeat center",
                                        backgroundSize: "contain",
                                        width: "100%",
                                        height: "100%",
                                        position: "fixed",
                                        zIndex: "10000",
                                        top: "0",
                                        left: "0",
                                        cursor: "zoom-out",
                                      }}
                                      onClick={closeEnlargedView}
                                    />
                                  )}
                                  <img
                                    src={`${BaseUrl}/${aadharback}`}
                                    className="img-fluid img-enlargable"
                                    alt=""
                                    style={{
                                      width: "120px",
                                      height: "100px",
                                      borderRadius: "30px",
                                    }}
                                    onClick={() =>
                                      handleImageClick(
                                        `${BaseUrl}/${aadharback}`
                                      )
                                    }
                                  />
                                </div>

                                <div className="w-100">
                                  <label htmlFor="" className="fw-bold">
                                    {" "}
                                    Aadhaar Back
                                  </label>
                                  <input
                                    type="file"
                                    name="image_2"
                                    className="form-control"
                                    onChange={handleBackAdhar}
                                  />
                                </div>
                              </FormGroup>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Aadhar
                              </label>
                              <input
                                type="text"
                                value={Adhar}
                                onChange={(e) => setAdhar(e.target.value)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Is Aadhar Verified
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="is_aadhaar_verified"
                                value={adharVerified}
                                onChange={(e) =>
                                  setAdharVerified(e.target.value)
                                }
                              >
                                <option selected>Open this select menu</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Is Pan Verified
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="is_aadhaar_verified"
                                value={panverified}
                                onChange={(e) => setPanVerified(e.target.value)}
                              >
                                <option selected>Open this select menu</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Pan
                              </label>
                              <input
                                type="text"
                                value={pan}
                                onChange={(e) => setPan(e.target.value)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="d-flex w-100 gap-3">
                              {/* <img
                                src={`${BaseUrl}/${panimg}`}
                                alt=""
                                className="img-fluid"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "30px",
                                }}
                              /> */}

                              <div>
                                {enlargedSrc && (
                                  <div
                                    className="enlarged-image-container"
                                    style={{
                                      background:
                                        "RGBA(0,0,0,.5) url(" +
                                        enlargedSrc +
                                        ") no-repeat center",
                                      backgroundSize: "contain",
                                      width: "100%",
                                      height: "100%",
                                      position: "fixed",
                                      zIndex: "10000",
                                      top: "0",
                                      left: "0",
                                      cursor: "zoom-out",
                                    }}
                                    onClick={closeEnlargedView}
                                  />
                                )}
                                <img
                                  src={`${BaseUrl}/${panimg}`}
                                  className="img-fluid img-enlargable"
                                  alt=""
                                  style={{
                                    width: "120px",
                                    height: "100px",
                                    borderRadius: "30px",
                                  }}
                                  onClick={() =>
                                    handleImageClick(`${BaseUrl}/${panimg}`)
                                  }
                                />
                              </div>

                              <div className="w-100">
                                <label htmlFor="" className="fw-bold">
                                  {" "}
                                  Pan Image
                                </label>
                                <input
                                  type="file"
                                  name="image_3"
                                  className="form-control"
                                  onChange={handlePan}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Gst
                              </label>
                              <input
                                type="text"
                                value={Gst}
                                onChange={(e) => setGst(e.target.value)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Gst Verified
                              </label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="is_aadhaar_verified"
                                value={gstVerified}
                                onChange={(e) => setgstVerified(e.target.value)}
                              >
                                <option selected>Open this select menu</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <h3 className="detailHeading">Bank Details</h3>
                            <Row className="bankDetail">
                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="acn" className="fw-bold">
                                    Account Number:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="acn"
                                    name="acn"
                                    value={accountNumber}
                                    onChange={(e) =>
                                      setAccountNumber(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="bn" className="fw-bold">
                                    Bank Name:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="bn"
                                    name="bn"
                                    value={bankName}
                                    onChange={(e) =>
                                      setBankName(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="ifsc" className="fw-bold">
                                    IFSC Code:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="ifsc"
                                    name="ifsc"
                                    value={ifscCode}
                                    onChange={(e) =>
                                      setIfscCode(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="ahn" className="fw-bold">
                                    Account Holder Name:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="ahn"
                                    name="ahn"
                                    value={accountHolderName}
                                    onChange={(e) =>
                                      setAccountHolderName(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>

                          {/* <Col md="12">
                            <div className="">
                              <div className="w-100 d-flex align-items-center justify-content-between mb-3">
                                <h3 className="detailHeading mb-0">
                                  Social Links
                                </h3>
                                <button
                                  onClick={addRow}
                                  className="btn bg-primary text-white"
                                >
                                  Add Row
                                </button>
                              </div>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Value</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {rows.map((row, index) => (
                                    <tr key={index}>
                                      <td>
                                        <FormGroup>
                                          <input
                                            type="text"
                                            name={`social_link[${index}].type`}
                                            placeholder="Type"
                                            className="form-control"
                                            value={row.type}
                                            onChange={(e) =>
                                              handleChange(
                                                index,
                                                "type",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </td>
                                      <td>
                                        <FormGroup>
                                          <textarea
                                            type="text"
                                            name={`more_info[${index}].value`}
                                            className="form-control"
                                            placeholder="Value"
                                            value={row.value}
                                            onChange={(e) =>
                                              handleChange(
                                                index,
                                                "value",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col> */}
                        </>
                      ) : null}

                      <Col md="12">
                        <div className="">
                          <button class="btn">Submit</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Loader open={open} />
      </section>
    </>
  );
}

export default adminLayout(UserDetailComp);
