



import React, { useEffect, useState } from "react";
import "../../assets/css/profile.css";
import userProfileLayout from "../../hoc/userProfileLayout";
import axios from "axios";
import { BaseUrl, token } from "../../utils/Token";
import adminLayout from "../../hoc/adminLayout";

const ProfilePage = () => {
    let userid = localStorage.getItem("userid")

    const [username, setusername] = useState("")

    const [img, setimg] = useState("")
    const [contact, setcontact] = useState("")
    const [Account, setAccount] = useState("")
    const [accountholder, setaccountholder] = useState("")
    const [bn, setbn] = useState("")
    const [ifsc, setifsc] = useState("")




    const [data, setdata] = useState([])
    const headers = {
        Authorization: `Bearer ${token}`
    }
    const handleprofile = () => {
        axios.get(`${BaseUrl}/fetch_users_admin?id=${userid}`, { headers }).then((res) => {
            // console.log(res)
            let data = res.data.data
            setdata(data)
            console.log(data)
            setusername(data.username)
            setimg(data.image)
            setcontact(data.phone)
            setAccount(data.bank_details.acn)
            setaccountholder(data.bank_details.ahn)
            setbn(data.bank_details.bn)

            setifsc(data.bank_details.ifsc)

        })
    }

    useEffect(() => {
        handleprofile()
    }, [])
    return (
        <>

            <div className="container">
                <div className="row profile">
                    <div className="col-md-3">
                        <div className="profile-sidebar">
                            <div className="my-3 p-3 bg-body rounded shadow-sm">
                                {/* <!-- SIDEBAR USERPIC --> */}
                                <div className="profile-userpic">
                                    <img
                                        src={`${BaseUrl}/${img}`}
                                        onError={(e) => {
                                            e.target.src = "https://via.placeholder.com/150";
                                        }}
                                        className="img-responsive profile-img-center img-fluid"
                                        alt=""
                                    />
                                </div>
                                {/* <!-- END SIDEBAR USERPIC -->
                            <!-- SIDEBAR USER TITLE --> */}
                                <div className="profile-usertitle">
                                    <div className="profile-usertitle-name">{data.username}</div>
                                    <div className="profile-usertitle-job">
                                        {data.type}
                                    </div>
                                </div>
                                {/* <!-- END SIDEBAR USER TITLE -->
                            <!-- SIDEBAR BUTTONS --> */}
                                {/* <div className="profile-userbuttons">
                                    <button type="button" className="btn btn-success btn-sm">
                                        Follow
                                    </button>
                                    <button type="button" className="btn btn-danger btn-sm">
                                        Message
                                    </button>
                                </div> */}
                                <hr />
                                {/* <div>
                                    <div className="bd-example">
                                        <div className="list-group">
                                            <NavLink
                                                to="/profile"
                                                className={({ isActive }) =>
                                                    `list-group-item list-group-item-action ${isActive ? "active" : ""
                                                    }`
                                                }
                                            >
                                                Personal Info
                                            </NavLink>
                                            <NavLink
                                                to="/change-password"
                                                className={({ isActive }) =>
                                                    `list-group-item list-group-item-action ${isActive ? "active" : ""
                                                    }`
                                                }
                                            >
                                                Change Password
                                            </NavLink>
                                            <NavLink
                                                to="/preferences"
                                                className={({ isActive }) =>
                                                    `list-group-item list-group-item-action ${isActive ? "active" : ""
                                                    }`
                                                }
                                            >
                                                Preferences
                                            </NavLink>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="profile-content">
                            <div className="my-3 p-3 bg-body rounded shadow-sm">
                                <h6 className="border-bottom text-primary pb-2 mb-0 mb-3">Personal Info</h6>
                                <form>
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Username
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={username}
                                                    onChange={(e) => setusername(e.target.value)}
                                                    placeholder="Username"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    <i className="fa fa-user"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Contact Number
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={contact}
                                                    onChange={(e) => setcontact(e.target.value)}
                                                    placeholder="Contact Number"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    <i className="fa fa-mobile"></i>
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="col">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Email address
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    value={email}
                                                    onChange={(e) => setemail(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Email Address"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    @
                                                </span>
                                            </div>
                                        </div> */}
                                    </div>



                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="">Bank Details</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Account Number
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={Account}
                                                    onChange={(e) => setAccount(e.target.value)}
                                                    placeholder="Account Number"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    <i class="fa-solid fa-building-columns"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Account Holder Name
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={accountholder}
                                                    onChange={(e) => setaccountholder(e.target.value)}
                                                    placeholder=" Account Holder Name"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    <i class="fa-solid fa-user"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Bank Name
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={bn}
                                                    onChange={(e) => setbn(e.target.value)}
                                                    placeholder="bn"
                                                    aria-label="Recipient's bn"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    <i class="fa-solid fa-building-columns"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleInputEmail1" className="form-label">
                                                Ifsc Code
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={ifsc}
                                                    onChange={(e) => setifsc(e.target.value)}
                                                    placeholder="ifsc"
                                                    aria-label="Recipient's ifsc"
                                                    aria-describedby="basic-addon2"
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    <i class="fa-solid fa-money-check-dollar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" className="btn bg-primary text-white">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default adminLayout(ProfilePage);
