

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
    // const [isClicked, setIsClicked] = useState(false);

    // const handleClick = () => {
    //     setIsClicked(!isClicked);
    // };
    return (
        <nav className={props.className} >
            <div className="container-fluid">
                <button
                    className={`navbar-toggler mobilenav `}
                    type="button"
                    onClick={props.handleClick}
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >

                </button>
                <div className="collapse navbar-collapse p-2" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">

                        <li >
                            <Link to={'/profile'}><img src="https://via.placeholder.com/150" className="rounded-circle" style={{ height: "45px", width: "45px" }}></img></Link>

                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    );
};

export default Header;
